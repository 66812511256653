<template>
	<!-- 贷款资料 -->
	<viewer v-if="disburseInfo">
		<a-descriptions bordered title="loan offer" size="default">
			<a-descriptions-item :label="$t('table.loanInfo.addInfo.number')" :span="3">
				{{ disburseInfo.loanOfferId }}
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.loanInfo.addInfo.file')" :span="3">
				<UploadPreviewFile :uploadFilePath="disburseInfo.loanOfferFile" :isUploadFileBtn="false"> </UploadPreviewFile>
			</a-descriptions-item>
		</a-descriptions>
		<a-tabs v-model="activeKey" :animated="true">
			<a-tab-pane v-for="(info, index) in disburseInfo.carInfos" :key="index" :tab="$t('table.applyInfo.tabsMenu.vehicle') + ' ' + (index + 1)">
				<a-descriptions v-if="loanType !== 7" bordered :title="$t('table.applyInfo.vehicleLabel.logFileNest')" size="default">
					<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.logFileNest')" :span="3">
						<UploadPreviewFile :uploadFilePath="info.ntsa.ntsaFile" :isUploadFileBtn="false"> </UploadPreviewFile>
					</a-descriptions-item>
					<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.vehicle')">
						{{ info.ntsa.make }}
					</a-descriptions-item>
					<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.model')">
						{{ info.ntsa.model }}
					</a-descriptions-item>
					<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.myear')">
						{{ info.ntsa.year }}
					</a-descriptions-item>
					<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.license')">
						{{ info.ntsa.registrationNumber }}
					</a-descriptions-item>
					<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.frame')">
						{{ info.ntsa.frameNumber }}
					</a-descriptions-item>
					<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.engineNo')">
						{{ info.ntsa.engineNo }}
					</a-descriptions-item>
					<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.attribution')" :span="3">
						<div class="box">
							<div class="list" v-for="(item, idx) in info.ntsa.belongs" :key="idx">
								<div class="span_labe"> {{ $t('table.applyInfo.vehicleLabel.pinCode') }}:{{ info.ntsa.belongs[idx].pin }} </div>
								<div class="span_labe"> {{ $t('table.applyInfo.vehicleLabel.ownerName') }}:{{ info.ntsa.belongs[idx].name }} </div>
							</div>
						</div>
					</a-descriptions-item>
					<a-descriptions-item label="NTSA In-charge/Transfer (options)" :span="3">
						<UploadPreviewFile :uploadFilePath="info.ntsaFile" :isUploadFileBtn="false"> </UploadPreviewFile>
					</a-descriptions-item>
				</a-descriptions>
				<a-descriptions v-if="loanType !== 7" bordered title="Tracking" size="default">
					<a-descriptions-item :label="$t('table.loanInfo.addInfo.traCer')" :span="3">
						<UploadPreviewFile :uploadFilePath="info.trackingCertificate" :isUploadFileBtn="false"> </UploadPreviewFile>
					</a-descriptions-item>
					<a-descriptions-item :label="$t('table.loanInfo.addInfo.traPin')" :span="3">
						<UploadPreviewFile :uploadFilePath="info.carTrackingPin" :isUploadFileBtn="false"> </UploadPreviewFile>
					</a-descriptions-item>
					<a-descriptions-item :label="$t('table.loanInfo.addInfo.photo') + $t('page.optional')" :span="3">
						<UploadPreviewFile :uploadFilePath="info.photos" :isUploadFileBtn="false"> </UploadPreviewFile>
					</a-descriptions-item>
				</a-descriptions>
				<p class="ant-descriptions-title" v-if="info.endorsements">Insurance Endorsement</p>
				<a-tabs :defaultActiveKey="0" type="card" :animated="true" style="margin-top: 20px" v-if="info.endorsements">
					<a-tab-pane :tab="'Insurance Endorsement ' + (info.endorsements.length - index)" v-for="(endor, index) in info.endorsements" :key="index">
						<a-descriptions bordered size="default">
							<a-descriptions-item label="Insurance Endorsement Letter (optional)" :span="3">
								<UploadPreviewFile :uploadFilePath.sync="endor.endorsementLetters" :isUploadFileBtn="false"></UploadPreviewFile>
							</a-descriptions-item>
							<a-descriptions-item label="Expiry Date" :span="3">
								{{ endor.expireDate }}
							</a-descriptions-item>
						</a-descriptions>
					</a-tab-pane>
				</a-tabs>
			</a-tab-pane>
		</a-tabs>
		<a-descriptions v-if="loanType !== 7" bordered title="Site Visit (optional)" size="default">
			<a-descriptions-item :label="$t('table.loanInfo.addInfo.addrDe')" :span="1">
				{{ disburseInfo.svAddressDetail }}
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.loanInfo.addInfo.mapLoc')" :span="2">
				<a @click="ShowAddress" v-if="IsShowAddress" style="margin: 0 10px; display: inline-block">
					{{ $t('table.applyInfo.applicationLabel.homeLatLog') }}
				</a>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.loanInfo.addInfo.photo')" :span="3">
				<UploadPreviewFile :uploadFilePath="disburseInfo.svPhotos" :isUploadFileBtn="false"></UploadPreviewFile>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.loanInfo.addInfo.sitVisRe')" :span="3">
				<UploadPreviewFile :uploadFilePath="disburseInfo.svReport" :isUploadFileBtn="false"></UploadPreviewFile>
			</a-descriptions-item>
		</a-descriptions>
		<a-descriptions bordered title="Additional Files" size="default">
			<a-descriptions-item v-if="loanType !== 7" :label="$t('table.loanInfo.addInfo.secAgre')" :span="3">
				<UploadPreviewFile :uploadFilePath="disburseInfo.afSecurityAgreements" :isUploadFileBtn="false"></UploadPreviewFile>
			</a-descriptions-item>
			<a-descriptions-item v-if="loanType !== 7" :label="$t('table.loanInfo.addInfo.brs') + $t('page.optional')" :span="3">
				<UploadPreviewFile :uploadFilePath="disburseInfo.afBrss" :isUploadFileBtn="false"></UploadPreviewFile>
			</a-descriptions-item>
			<a-descriptions-item v-if="loanType !== 7" :label="$t('table.loanInfo.addInfo.postDatChe') + $t('page.optional')" :span="3">
				<UploadPreviewFile :uploadFilePath="disburseInfo.afPostDatedCheques" :isUploadFileBtn="false"></UploadPreviewFile>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.loanInfo.addInfo.loanCalc')" :span="3">
				<UploadPreviewFile :uploadFilePath="disburseInfo.afLoanCalculator" :isUploadFileBtn="false"></UploadPreviewFile>
			</a-descriptions-item>
			<a-descriptions-item v-if="loanType !== 7" :label="$t('table.loanInfo.addInfo.letOfUnder') + $t('page.optional')" :span="3">
				<UploadPreviewFile :uploadFilePath="disburseInfo.afLetterOfUndertakings" :isUploadFileBtn="false"></UploadPreviewFile>
			</a-descriptions-item>
			<a-descriptions-item v-if="loanType !== 7" :label="$t('table.loanInfo.addInfo.letOfUnderRes') + $t('page.optional')" :span="3">
				<UploadPreviewFile :uploadFilePath="disburseInfo.afLetterOfUndertakingResponses" :isUploadFileBtn="false"></UploadPreviewFile>
			</a-descriptions-item>
		</a-descriptions>
		<a-descriptions class="table-descriptions" :title="$t('table.applyInfo.applicationLabel.supDoc')" size="default">
			<a-descriptions-item :span="3">
				<a-table
					style="font-size: 12px"
					:columns="supCloums"
					size="default"
					:rowKey="(record, index) => index"
					:dataSource="disburseInfo.supplementFiles"
					:pagination="false"
					bordered
				>
					<span slot="file" slot-scope="obj">
						<a v-bind:href="$Util.getObsImageUrl($store.state.pageState.authObj, obj.file)" target="_blank">{{
							!$Util.isEmpty(obj.file) ? obj.file.split('/').at(-1) : ''
						}}</a>
					</span>
				</a-table>
			</a-descriptions-item>
		</a-descriptions>
		<div v-if="disburseInfo.payees.length > 0">
			<div style="color: rgba(0, 0, 0, 0.85); font-weight: bold; font-size: 16px; line-height: 1.5">{{
				$t('table.applyInfo.tabsMenu.pushRecord')
			}}</div>
			<a-descriptions
				bordered
				size="default"
				v-for="(item, idx) in disburseInfo.payees"
				:key="item.id"
				:title="$t('table.applyInfo.amountCloums.pay') + (idx + 1)"
			>
				<a-descriptions-item :label="$t('table.applyInfo.amountCloums.payName')" prop="disburseName">
					{{ item.disburseName }}
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.amountCloums.payAccount')" prop="disburseAccount">
					{{ item.disburseAccount }}
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.amountCloums.payType')" prop="disburseType">
					{{ item.disburseType }}
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.amountCloums.payAmount')" prop="disburseAmount">
					{{ item.disburseAmount }}
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.amountCloums.payTime')" prop="paidTime">
					{{ item.paidTime }}
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.amountCloums.actionTime')" prop="operationTime">
					{{ item.operationTime }}
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.Disbursement-Receipt')">
					<UploadPreviewFile :uploadFilePath="item.disburseCertificateFile" :isUploadFileBtn="false"></UploadPreviewFile>
				</a-descriptions-item>
			</a-descriptions>
		</div>
		<!-- 地图 -->
		<a-modal v-drag-modal v-model="MapModal.show" title="Map" :destroyOnClose="true" width="800px" :footer="null">
			<GmapMap :center="MapModal.center" :zoom="12" map-type-id="roadmap" style="width: 100%; height: 400px" ref="mapRef">
				<GmapMarker :position="MapModal.markpostion" :clickable="true" :draggable="false" ref="mark"> </GmapMarker>
			</GmapMap>
		</a-modal>
	</viewer>
	<a-empty v-else style="margin-top: 20px" />
</template>
<script>
export default {
	props: ['disburseInfo', 'loanType'],
	data() {
		return {
			activeKey: 0,
			IsShowAddress: false,
			centerlatlng: {},
			MapModal: {
				show: false,
				name: '',
				center: { lat: -1.294403, lng: 36.817582 },
				markpostion: { lat: -1.294403, lng: 36.817582 }
			}
		}
	},
	created() {
		this.$nextTick(() => {
			if (this.disburseInfo.svMapLocation) {
				let arr = this.disburseInfo.svMapLocation.split(',')
				this.IsShowAddress = arr.length == 2
				if (this.IsShowAddress) {
					this.centerlatlng = {
						lat: Number(arr[0]),
						lng: Number(arr[1])
					}
				}
			}
		})
	},
	computed: {
		supCloums() {
			return [
				{
					// 文件名称
					title: this.$t('table.applyInfo.applicationLabel.supCloums.fileName'),
					dataIndex: 'filename',
					align: 'center',
					width: 300
				},
				{
					// 文件
					title: this.$t('table.applyInfo.applicationLabel.supCloums.file'),
					scopedSlots: { customRender: 'file' },
					align: 'center'
				},
				{
					// 文件备注
					title: this.$t('table.applyInfo.applicationLabel.supCloums.fileRemark'),
					dataIndex: 'note',
					align: 'center'
				}
			]
		}
	},
	methods: {
		// 显示当前坐标位置
		ShowAddress() {
			this.MapModal.center = this.centerlatlng
			this.MapModal.markpostion = this.centerlatlng
			this.MapModal.show = true
		}
	}
}
</script>
<style scoped lang="less">
.ant-form-item {
	margin-bottom: 10px;
}
img {
	display: inline-block;
	width: 100px;
	height: 100px;
	margin-right: 20px;
	cursor: pointer;
}
</style>
