<template>
	<viewer>
		<a-descriptions bordered :title="$t('table.applyInfo.personalLabel.basic')" size="default">
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.firstName')">
				{{ personalInfo.basicFirstName }}
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.middleName')">
				{{ personalInfo.basicMiddleName }}
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.lastName')">
				{{ personalInfo.basicLastName }}
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.sex')">
				{{ $t(`table.applyInfo.personalLabel.sexMap.${personalInfo.basicSex}`) }}
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.mobile')">
				<template v-if="personalInfo.mobiles.length > 0">
					<div v-for="item in personalInfo.mobiles" :key="item.id" style="margin-bottom: 5px">
						{{ item.mobile }}
					</div>
				</template>
				<template v-else>
					{{ personalInfo.basicMobile }}
				</template>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.idNumber')">
				{{ personalInfo.basicIdentityCard }}
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.dateOfBirth')">
				{{ personalInfo.birthday }}
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.Permission.User.email')" :span="2">
				{{ personalInfo.email }}
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.customerPic')" :span="3">
				<UploadPreviewFile :uploadFilePath="personalInfo.customerIdPic" :isUploadFileBtn="false"> </UploadPreviewFile>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.identityPic')" :span="3">
				<UploadPreviewFile
					:uploadFilePath="[personalInfo.identityCardFront, personalInfo.identityCardBack, personalInfo.identityCardHold]"
					:isUploadFileBtn="false"
				>
				</UploadPreviewFile>
			</a-descriptions-item>
		</a-descriptions>
		<a-descriptions bordered :title="$t('table.applyInfo.personalLabel.pinfile')" size="default">
			<a-descriptions-item label="PIN" :span="3">
				{{ personalInfo.pin.pinNum }}
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.taxpayer')">
				{{ personalInfo.pin.taxpayerName }}
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.pinStatus')">
				{{ personalInfo.pin.status }}
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.taxStatus')">
				{{ personalInfo.pin.itaxStatus }}
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.taxInstitution')">
				{{ personalInfo.pin.itaxObligation }}
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.effectiveTime')">
				{{ personalInfo.pin.effectiveTime }}
			</a-descriptions-item>
		</a-descriptions>
		<a-descriptions class="line-top-descriptions" bordered :title="$t('table.applyInfo.personalLabel.source')" size="default">
			<a-descriptions-item :label="$t('table.CustomerManagement.salayBand')" :span="3">
				{{ personalInfo.incomeSource.salaryBand }}
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.loanInfo.caseInfo.employmentStatus')" :span="3">
				{{ personalInfo.incomeSource.employmentStatus }}
			</a-descriptions-item>
			<a-descriptions-item
				v-if="personalInfo.incomeSource.employmentStatus !== 'UNEMPLOYMENT'"
				:label="$t('table.loanInfo.caseInfo.noOfYears')"
				:span="3"
			>
				{{ personalInfo.incomeSource.noOfYears }}
			</a-descriptions-item>
		</a-descriptions>
		<a-descriptions
			class="line-bottom-descriptions"
			v-if="personalInfo.incomeSource.employmentStatus == 'PUBLIC SECTOR' || personalInfo.incomeSource.employmentStatus == 'PRIVATE SECTOR'"
			bordered
			size="default"
		>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.company') + $t('page.optional')">
				{{ personalInfo.incomeSource.company }}
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.employer') + $t('page.optional')">
				{{ personalInfo.incomeSource.employerName }}
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.employerMobile') + $t('page.optional')">
				{{ personalInfo.incomeSource.employerMobile }}
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.employerEmail') + $t('page.optional')">
				{{ personalInfo.incomeSource.employerEmail }}
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.companyNo') + $t('page.optional')">
				{{ personalInfo.incomeSource.companyAccount }}
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.salaryReceivedNo') + $t('page.optional')">
				{{ personalInfo.incomeSource.salaryAccount }}
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.remark') + $t('page.optional')" :span="3">
				{{ personalInfo.incomeSource.remarks }}
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.documentation') + $t('page.optional')">
				<UploadPreviewFile :uploadFilePath="personalInfo.incomeSource.file" :isUploadFileBtn="false"> </UploadPreviewFile>
			</a-descriptions-item>
		</a-descriptions>
		<a-descriptions class="line-bottom-descriptions" v-if="personalInfo.incomeSource.employmentStatus == 'BUSINESS'" bordered size="default">
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.company') + $t('page.optional')" :span="3">
				{{ personalInfo.incomeSource.company }}
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.remark') + $t('page.optional')" :span="3">
				{{ personalInfo.incomeSource.remarks }}
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.documentation') + $t('page.optional')" :span="3">
				<UploadPreviewFile :uploadFilePath="personalInfo.incomeSource.file" :isUploadFileBtn="false"></UploadPreviewFile>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.docCr2') + $t('page.optional')" :span="3">
				<UploadPreviewFile :uploadFilePath="personalInfo.incomeSource.cr2Documents" :isUploadFileBtn="false"></UploadPreviewFile>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.docBR') + $t('page.optional')" :span="3">
				<UploadPreviewFile :uploadFilePath="personalInfo.incomeSource.boardResolution" :isUploadFileBtn="false"></UploadPreviewFile>
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.docBTL') + $t('page.optional')" :span="3">
				<UploadPreviewFile :uploadFilePath="personalInfo.incomeSource.businessTradeLicence" :isUploadFileBtn="false"></UploadPreviewFile>
			</a-descriptions-item>
		</a-descriptions>
		<a-descriptions class="line-bottom-descriptions" v-if="personalInfo.incomeSource.employmentStatus == 'UNEMPLOYMENT'" bordered size="default">
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.remark') + $t('page.optional')">
				{{ personalInfo.incomeSource.remarks }}
			</a-descriptions-item>
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.documentation') + $t('page.optional')">
				<UploadPreviewFile :uploadFilePath="personalInfo.incomeSource.file" :isUploadFileBtn="false"></UploadPreviewFile>
			</a-descriptions-item>
		</a-descriptions>
		<a-descriptions bordered :title="$t('table.applyInfo.personalLabel.loanPurpose')" size="default">
			<a-descriptions-item :label="$t('table.applyInfo.personalLabel.loanPurpose')">
				{{ personalInfo.loanUse }}
			</a-descriptions-item>
		</a-descriptions>
	</viewer>
</template>
<script>
export default {
	props: ['personalInfo']
}
</script>
