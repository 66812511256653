<template>
	<!-- 保险信息 start -->
	<div class="ant-descriptions ant-descriptions-bordered">
		<span class="row">
			<div class="ant-descriptions-title">{{ $t('table.applyInfo.loanApplyLabel.insurance') }}</div>
			<a-button
				v-if="isShowDisbursementRecords && insuranceData[tabActiveKey].hasInsurance"
				type="primary"
				style="margin-left: 10px"
				:disabled="!hasViewPermission()"
				@click="toIpfDisbursementRecords"
			>
				Disbursement Records
			</a-button>
			<!-- <a-button v-if="beingRevised && this.insuranceData.length < 2" style="margin-left: 20px" type="primary" @click="addIPF"> Add IPF</a-button> -->
		</span>
		<a-tabs v-model="tabActiveKey" type="editable-card" :animated="true" style="margin-top: 20px" hideAdd>
			<div
				v-if="insuranceData[Number(ipfActiveKey)]?.newInput || (showEditUpfront && editInsInfo)"
				slot="tabBarExtraContent"
				style="position: absolute; left: 150px; top: 0px; z-index: 1"
			>
				<edit-deduction-modal :upfrontReduction.sync="insuranceData[Number(ipfActiveKey)]" isFromIPF @onSubmitSuccess="getInsTryCalculate">
					<a-button slot="reference" style="margin-left: 10px" type="primary">Edit Upfront Fee</a-button>
				</edit-deduction-modal>
			</div>
			<a-tab-pane
				:tab="'IPF ' + (insuranceData.length - index)"
				v-for="(insurInfo, index) in insuranceData"
				:key="String(index)"
				:closable="insurInfo.newInput"
			>
				<!-- <template slot="tab">
					{{ 'IPF ' + (insuranceData.length - index) }}
					<span v-if="insurInfo.newInput" class="new-tip">new</span>
				</template> -->
				<template v-if="insurInfo.newInput && false">
					<a-descriptions bordered size="default">
						<a-descriptions-item :label="$t('table.applyInfo.loanApplyLabel.insurance')">
							<a-radio-group v-model="insurInfo.hasInsurance" style="margin: 10px 0">
								<a-radio :value="1"> Yes </a-radio>
								<a-radio :value="0"> No </a-radio>
							</a-radio-group>
							<a-form class="insurance-form" :label-col="{ span: 10 }" :wrapper-col="{ span: 14 }" v-if="insurInfo.hasInsurance === 1">
								<a-form-item label="Principal Amount">
									<a-input-number :precision="2" v-model="insurInfo.insuranceAmount" :min="0" @change="getInsTryCalculate(true)" />
								</a-form-item>
								<a-form-item label="Installment Principal">
									<a-statistic
										:precision="2"
										:value="insurInfo.insuranceInstallmentPrincipal == null ? '' : insurInfo.insuranceInstallmentPrincipal.toFixed(2)"
									/>
								</a-form-item>
								<a-form-item label="Interest">
									<a-input-number
										:min="0"
										:max="100"
										:formatter="(value) => `${value}%`"
										:parser="(value) => value.replace('%', '')"
										:precision="1"
										v-model="insurInfo.insuranceInterest"
										@change="getInsTryCalculate"
									/>
								</a-form-item>
								<a-form-item label="Installment Interest">
									<a-statistic
										:precision="2"
										:value="insurInfo.insuranceInstallmentInterest == null ? '' : insurInfo.insuranceInstallmentInterest.toFixed(2)"
									/>
								</a-form-item>
								<a-form-item label="Period">
									<a-input-number :min="0" :step="1" :precision="0" v-model="insurInfo.insuranceInstallments" @change="getInsTryCalculate" />
								</a-form-item>
								<a-form-item label="Installment from 2nd month">
									<a-statistic
										:precision="2"
										:value="
											insurInfo.insuranceInstallmentPrincipal + insurInfo.insuranceInstallmentInterest == null
												? ''
												: Math.round(insurInfo.insuranceInstallmentPrincipal + insurInfo.insuranceInstallmentInterest).toFixed(2)
										"
									/>
								</a-form-item>
								<a-form-item label="Upfront Fee - Deduction">
									<a-statistic :precision="2" :value="insurInfo?.toBeDeducedUpfront" />
								</a-form-item>
								<a-form-item label="Net Disbursement Amount">
									<a-statistic :precision="2" :value="insurInfo?.netDisbursementAmount" />
								</a-form-item>
							</a-form>
							<!-- <a-button style="margin-top: 10px" type="primary" @click="ipfSave">{{ $t('operate.save') }}</a-button> -->
						</a-descriptions-item>
					</a-descriptions>
				</template>
				<template v-else>
					<template v-if="isEditable">
						<a-descriptions bordered size="default">
							<a-descriptions-item :label="$t('table.applyInfo.loanApplyLabel.insurance')">
								<a-radio-group v-model="insurInfo.hasInsurance" style="margin: 10px 0" :disabled="!editInsInfo">
									<a-radio :value="1"> Yes </a-radio>
									<a-radio :value="0"> No </a-radio>
								</a-radio-group>
								<a-form class="insurance-form" :label-col="{ span: 6 }" :wrapper-col="{ span: 6 }" v-if="insurInfo.hasInsurance === 1">
									<a-form-item label="Principal Amount">
										<a-input-number
											:precision="2"
											v-model="insurInfo.insuranceAmount"
											:min="0"
											@change="getInsTryCalculate"
											:disabled="!editInsInfo"
										/>
									</a-form-item>
									<a-form-item label="Interest">
										<a-input-number
											:min="0"
											:max="100"
											:formatter="(value) => `${value}%`"
											:parser="(value) => value.replace('%', '')"
											:precision="1"
											v-model="insurInfo.insuranceInterest"
											:disabled="!editInsInfo"
											@change="getInsTryCalculate"
										/>
									</a-form-item>
									<a-form-item label="Period">
										<a-input-number
											:min="0"
											:step="1"
											:precision="0"
											v-model="insurInfo.insuranceInstallments"
											@change="getInsTryCalculate"
											:disabled="!editInsInfo"
										/>
									</a-form-item>
									<a-form-item label="Installment Principal">
										<a-statistic
											:precision="2"
											:value="insurInfo.insuranceInstallmentPrincipal == null ? '' : insurInfo.insuranceInstallmentPrincipal.toFixed(2)"
										/>
									</a-form-item>
									<a-form-item label="Installment Interest">
										<a-statistic
											:precision="2"
											:value="insurInfo.insuranceInstallmentInterest == null ? '' : insurInfo.insuranceInstallmentInterest.toFixed(2)"
										/>
									</a-form-item>
									<a-form-item label="Installment from 2nd month">
										<a-statistic
											:precision="2"
											:value="
												insurInfo.insuranceInstallmentPrincipal + insurInfo.insuranceInstallmentInterest == null
													? ''
													: Math.round(insurInfo.insuranceInstallmentPrincipal + insurInfo.insuranceInstallmentInterest).toFixed(2)
											"
										/>
									</a-form-item>
									<a-form-item label="Upfront Fee - Deduction">
										<show-deduction-modal
											:upfrontReduction="{ collectedUpfront: insurInfo.collectedUpfront, toBeDeducedUpfront: insurInfo.toBeDeducedUpfront }"
											isFromIPF
										>
											<a slot="reference">
												<a-statistic :precision="2" :value="insurInfo.toBeDeducedUpfront" />
											</a>
										</show-deduction-modal>
									</a-form-item>
									<a-form-item label="Net Disbursement Amount">
										<a-statistic :precision="2" :value="insurInfo.netDisbursementAmount" />
									</a-form-item>
								</a-form>
								<a-button v-if="!editInsInfo" type="primary" @click="handleEditInfo(true)"> {{ $t('page.edit') }} </a-button>
								<a-button v-if="editInsInfo" type="primary" @click="handleSubmit"> {{ $t('operate.save') }} </a-button>
								<a-button v-if="editInsInfo" type="primary" @click="handleEditInfo(false)" style="margin-left: 20px">
									{{ $t('operate.cancel') }}
								</a-button>
							</a-descriptions-item>
						</a-descriptions>
						<a-descriptions
							v-if="insurInfo.supplementFiles"
							class="table-descriptions"
							:title="$t('table.applyInfo.applicationLabel.supDoc')"
							size="default"
						>
							<a-descriptions-item>
								<a-table
									style="font-size: 12px"
									:columns="supCloums"
									size="default"
									:rowKey="(record, index) => index"
									:dataSource="insurInfo.supplementFiles"
									:pagination="false"
									bordered
								>
									<span slot="file" slot-scope="obj">
										<a v-bind:href="$Util.getObsImageUrl($store.state.pageState.authObj, obj.file)" target="_blank">{{
											!$Util.isEmpty(obj.file) ? obj.file.split('/').at(-1) : ''
										}}</a>
									</span>
								</a-table>
							</a-descriptions-item>
						</a-descriptions>
					</template>
					<template v-else>
						<a-descriptions bordered v-if="insurInfo.hasInsurance === 0 || insurInfo.hasInsurance === null">
							<a-descriptions-item :label="$t('table.applyInfo.loanApplyLabel.insurance')">No</a-descriptions-item>
						</a-descriptions>
						<div v-else class="ipf-yes-descriptions">
							<div class="row-center ant-descriptions-bordered ipf-wrapper">IPF</div>
							<a-descriptions bordered :column="2">
								<a-descriptions-item label="Principal Amount">
									<a-statistic :precision="2" :value="insurInfo.insuranceAmount == null ? '' : insurInfo.insuranceAmount" />
								</a-descriptions-item>
								<a-descriptions-item label="Installment Principal">
									<a-statistic
										:precision="2"
										:value="insurInfo.insuranceInstallmentPrincipal == null ? '' : insurInfo.insuranceInstallmentPrincipal.toFixed(2)"
									/>
								</a-descriptions-item>
								<a-descriptions-item label="Interest">
									<a-statistic :precision="1" :value="insurInfo.insuranceInterest" suffix="%" />
								</a-descriptions-item>
								<a-descriptions-item label="Installment Interest">
									<a-statistic
										:precision="2"
										:value="insurInfo.insuranceInstallmentInterest == null ? '' : insurInfo.insuranceInstallmentInterest.toFixed(2)"
									/>
								</a-descriptions-item>
								<a-descriptions-item label="Period">
									<a-statistic :value="insurInfo.insuranceInstallments" />
								</a-descriptions-item>
								<a-descriptions-item label="Installment from 2nd month">
									<a-statistic
										:precision="2"
										:value="
											insurInfo.insuranceInstallmentPrincipal + insurInfo.insuranceInstallmentInterest == null
												? ''
												: Math.round(insurInfo.insuranceInstallmentPrincipal + insurInfo.insuranceInstallmentInterest).toFixed(2)
										"
									/>
								</a-descriptions-item>
								<a-descriptions-item label="Upfront Fee - Deduction">
									<show-deduction-modal
										:upfrontReduction="{ collectedUpfront: insurInfo.collectedUpfront, toBeDeducedUpfront: insurInfo.toBeDeducedUpfront }"
										isFromIPF
									>
										<a slot="reference">
											<a-statistic :precision="2" :value="insurInfo.toBeDeducedUpfront" />
										</a>
									</show-deduction-modal>
								</a-descriptions-item>
								<a-descriptions-item label="Net Disbursement Amount">
									<a-statistic :precision="2" :value="insurInfo.netDisbursementAmount" />
								</a-descriptions-item>
								<a-descriptions-item v-if="isShowDisbursementRecords && index === 0" label="Disbursed Amount">
									<a-statistic :precision="2" :value="disbursedAmount" />
								</a-descriptions-item>
							</a-descriptions>
						</div>
						<a-descriptions
							v-if="insurInfo.supplementFiles"
							class="table-descriptions"
							:title="$t('table.applyInfo.applicationLabel.supDoc')"
							size="default"
						>
							<a-descriptions-item>
								<a-table
									style="font-size: 12px"
									:columns="supCloums"
									size="default"
									:rowKey="(record, index) => index"
									:dataSource="insurInfo.supplementFiles"
									:pagination="false"
									bordered
								>
									<span slot="file" slot-scope="obj">
										<a v-bind:href="$Util.getObsImageUrl($store.state.pageState.authObj, obj.file)" target="_blank">{{
											!$Util.isEmpty(obj.file) ? obj.file.split('/').at(-1) : ''
										}}</a>
									</span>
								</a-table>
							</a-descriptions-item>
						</a-descriptions>
					</template>
				</template>
			</a-tab-pane>
		</a-tabs>
	</div>

	<!-- 保险信息 end -->
</template>
<script>
import { applyConfirmInsuranceInfo, insTryCalculate } from '@/api/loan'
import { apiApplyConfirmInsuranceInfo, apiIpfTryCalculate, apiIpfSave } from '@/api/insuranceRenewal'
import { getDisbursedAmount } from '@/api/loan'
import ShowDeductionModal from '@/views/businessComponents/show-deduction-modal.vue'
import EditDeductionModal from '@/views/businessComponents/edit-deduction-modal.vue'

export default {
	components: {
		ShowDeductionModal,
		EditDeductionModal
	},
	props: ['detailData', 'insuranceData', 'isEditable', 'statusCode', 'beingRevised', 'showEditUpfront'],
	data() {
		return {
			oldInsuranceData: {},
			editInsInfo: false,
			tabActiveKey: '0',
			isShowDisbursementRecords:
				['menus.loanDataMenu.pendingDetail', 'menus.insuranceRenewalMenu.renewalDetail'].includes(this.$route.name) &&
				['/home/loandata/pendinglist', '/home/insuranceRenewal/renewalPending'].includes(this.$route.query.from),
			disbursedAmount: '',
			ipfActiveKey: '0',
			addedIpf: false
		}
	},
	computed: {
		supCloums() {
			return [
				{
					// 文件名称
					title: this.$t('table.applyInfo.applicationLabel.supCloums.fileName'),
					dataIndex: 'filename',
					align: 'center',
					width: 300
				},
				{
					// 文件
					title: this.$t('table.applyInfo.applicationLabel.supCloums.file'),
					scopedSlots: { customRender: 'file' },
					align: 'center'
				},
				{
					// 文件备注
					title: this.$t('table.applyInfo.applicationLabel.supCloums.fileRemark'),
					dataIndex: 'note',
					align: 'center'
				}
			]
		}
	},
	watch: {
		detailData: {
			handler: async function (newValue) {
				if (newValue?.id && this.isShowDisbursementRecords && this.insuranceData[0]?.hasInsurance) {
					this.fetchDisbursedAmount()
				}
			},
			immediate: true
		}
	},
	methods: {
		async fetchDisbursedAmount() {
			const params = {
				loanId: this.detailData.id,
				loanType: this.detailData.loanType,
				disburseLoanType: 8
			}
			this.disbursedAmount = await getDisbursedAmount(params)
		},
		getInsTryCalculate() {
			if (!this.insuranceData[0].insuranceAmount) {
				return false
			}
			if (this.statusCode === 'ipf_info_4') {
				const params = {
					id: this.insuranceData[0].id,
					loanId: this.insuranceData[0].loanId,
					hasInsurance: this.insuranceData[0].hasInsurance,
					insuranceAmount: this.insuranceData[0].insuranceAmount,
					insuranceInterest: this.insuranceData[0].insuranceInterest / 100,
					insuranceInstallments: this.insuranceData[0].insuranceInstallments
				}
				if (this.insuranceData[0].hasOwnProperty('toBeDeducedUpfront')) {
					params.toBeDeducedUpfront = this.insuranceData[0].toBeDeducedUpfront
				}
				apiIpfTryCalculate(params).then((res) => {
					this.insuranceData[0].insuranceInstallmentPrincipal = res.insuranceInstallmentPrincipal
					this.insuranceData[0].insuranceInstallmentInterest = res.insuranceInstallmentInterest
					this.insuranceData[0].netDisbursementAmount = res.netDisbursementAmount
				})
			} else {
				const params = {
					insuranceAmount: this.insuranceData[0].insuranceAmount,
					insuranceInterest: this.insuranceData[0].insuranceInterest / 100,
					insuranceInstallments: this.insuranceData[0].insuranceInstallments
				}
				if (this.insuranceData[0].hasOwnProperty('toBeDeducedUpfront')) {
					params.toBeDeducedUpfront = this.insuranceData[0].toBeDeducedUpfront
				}
				if (this.insuranceData[0].hasOwnProperty('collectedUpfront')) {
					params.collectedUpfront = this.insuranceData[0].collectedUpfront
				}
				if (this.insuranceData[0].hasOwnProperty('upfrontSwitch')) {
					params.upfrontSwitch = this.insuranceData[0].upfrontSwitch || 0
				}
				insTryCalculate(params).then((res) => {
					this.insuranceData[0].insuranceInstallmentPrincipal = res.insuranceInstallmentPrincipal
					this.insuranceData[0].insuranceInstallmentInterest = res.insuranceInstallmentInterest
					this.insuranceData[0].netDisbursementAmount = res.netDisbursementAmount
				})
			}
		},
		handleEditInfo(bool) {
			if (bool === false) {
				Object.keys(this.insuranceData[0]).forEach((item) => {
					this.insuranceData[0][item] = JSON.parse(this.oldInsuranceData)[item]
				})
			} else if (bool === true) {
				this.oldInsuranceData = JSON.stringify(this.insuranceData[0])
				if (!this.insuranceData[0].insuranceInterest) {
					this.insuranceData[0].insuranceInterest = 4
				}
				if (!this.insuranceData[0].insuranceInstallments) {
					this.insuranceData[0].insuranceInstallments = 3
				}
			}
			this.editInsInfo = bool
		},
		handleSubmit() {
			if (this.insuranceData[0].hasInsurance === 1) {
				if (this.$Util.isEmpty(this.insuranceData[0].insuranceAmount)) {
					this.$message.error('Principal Amount is required')
					return false
				} else if (this.$Util.isEmpty(this.insuranceData[0].insuranceInterest)) {
					this.$message.error('Interest is required')
					return false
				} else if (this.$Util.isEmpty(this.insuranceData[0].insuranceInstallments)) {
					this.$message.error('Period is required')
					return false
				}
			}
			this.statusCode === 'ipf_info_4'
				? apiApplyConfirmInsuranceInfo({
						id: this.insuranceData[0].id,
						loanId: this.insuranceData[0].loanId,
						hasInsurance: this.insuranceData[0].hasInsurance, //是否有保险
						insuranceAmount: this.insuranceData[0].insuranceAmount, //保险本金
						insuranceInterest: this.insuranceData[0].insuranceInterest / 100, //保险利率
						insuranceInstallments: this.insuranceData[0].insuranceInstallments //保险分期
				  }).then(() => {
						this.editInsInfo = false
						this.$message.success('Success')
						this.$emit('setInsuranceCompleted', true)
				  })
				: applyConfirmInsuranceInfo({
						loanId: this.insuranceData[0].loanId,
						hasInsurance: this.insuranceData[0].hasInsurance, //是否有保险
						insuranceAmount: this.insuranceData[0].insuranceAmount, //保险本金
						insuranceInterest: this.insuranceData[0].insuranceInterest / 100, //保险利率
						insuranceInstallments: this.insuranceData[0].insuranceInstallments, //保险分期
						toBeDeducedUpfront: this.insuranceData[0].toBeDeducedUpfront, //待减去的贷前费用
						collectedUpfront: this.insuranceData[0].collectedUpfront,
						upfrontSwitch: this.insuranceData[0].upfrontSwitch || 0,
				  }).then(() => {
						this.editInsInfo = false
						this.$message.success('Success')
						this.$emit('setInsuranceCompleted', true)
				  })
		},
		toIpfDisbursementRecords() {
			this.$router.push({
				path: 'ipfDisbursementRecords',
				query: {
					loanId: this.detailData.id,
					loanType: this.detailData.loanType,
					disburseType: this.detailData.loanInfo.disburseType || 'dtb',
					disburseLoanType: 8
				}
			})
		},
		hasViewPermission() {
			switch (this.$route.name) {
				case 'menus.loanDataMenu.pendingDetail':
					return this.$hasPermission('ROLE_PENGING_LOAN_VIEW')
				case 'menus.insuranceRenewalMenu.renewalDetail':
					return this.$hasPermission('ROLE_RENEWAL_PENDING_VIEW')
				case 'menus.emergencyLoanMenu.detail':
					return this.$hasPermission('ROLE_EMERGENCY_DISBURSE_WAITING_VIEW')
				default:
					return false
			}
		}
		// addIPF() {
		// 	this.insuranceData.unshift({
		// 		loanId: this.$route.query.id,
		// 		hasInsurance: 1,
		// 		insuranceAmount: 0,
		// 		insuranceInterest: 4,
		// 		insuranceInstallments: 3,
		// 		insuranceInstallmentPrincipal: 0,
		// 		insuranceInstallmentInterest: 0,
		// 		supplementFiles: [],
		// 		newInput: true,
		// 		delete: false,
		// 		toBeDeducedUpfront: null
		// 	})
		// 	this.addedIpf = true
		// },
		// removeIPF(targetKey, action) {
		// 	if (action === 'remove') {
		// 		if (this.insuranceData[0].delete === false) {
		// 			this.insuranceData.shift()
		// 		} else {
		// 			this.insuranceData[0].delete = true
		// 			let endorsements = []
		// 			this.disburseInfo?.carInfos?.forEach((carInfo) => {
		// 				carInfo.endorsements.forEach((endor) => {
		// 					if (endor.newInput) {
		// 						endor.delete = true
		// 						endorsements.push(endor)
		// 					}
		// 				})
		// 			})
		// 			apiIpfSave({
		// 				loanInsuranceInfo: this.insuranceData[0],
		// 				endorsements: endorsements
		// 			}).then(() => {
		// 				this.$message.success('Delete Success')
		// 				location.reload()
		// 			})
		// 		}
		// 		this.addedIpf = false
		// 	}
		// },
		// ipfSave() {
		// 	if (!this.addedIpf) {
		// 		return false
		// 	}
		// 	this.insuranceData[0].insuranceInterest = this.insuranceData[0].insuranceInterest / 100
		// 	let endorsements = []
		// 	this.disburseInfo?.carInfos?.forEach((carInfo) => {
		// 		carInfo.endorsements.forEach((endor) => {
		// 			if (endor.newInput) {
		// 				endorsements.push(endor)
		// 			}
		// 		})
		// 	})
		// 	apiIpfSave({
		// 		loanInsuranceInfo: this.insuranceData[0],
		// 		endorsements: endorsements
		// 	}).then(() => {
		// 		this.$message.success('success')
		// 		// this.closeTab(this.$route.fullPath)
		// 		// this.$router.push('/home/insuranceRenewal/renewalList')
		// 	})
		// }
	}
}
</script>
<style lang="less">
.ipf-yes-descriptions {
	display: flex;

	.ipf-wrapper {
		margin: 20px 0;
		width: 200px;
		border: 1px solid #e8e8e8;
		border-right: 0;
		border-radius: 6px 0 0 6px;
	}

	.ant-descriptions-view {
		border-radius: 0px 6px 6px 0px;
	}
}
.new-tip {
	position: relative;
	top: -10px;
	right: -17px;
	color: #fff;
	background: rgba(255, 0, 0, 0.6);
	padding: 2px 6px;
	border-radius: 5px;
}

.table-descriptions {
	.ant-descriptions-row {
		border-bottom: none;
		padding: 0;
	}
	.ant-descriptions-item-label {
		display: none;
	}
	.ant-descriptions-item-content {
		width: 100%;
		padding: 0;
	}
	.ant-table table {
		width: -webkit-fill-available;
	}
	.ant-descriptions-view {
		border: none;
	}
}
</style>
