<template>
	<!-- 财务资料 -->
	<div>
		<viewer>
			<a-descriptions
				class="table-descriptions"
				:title="
					financeInfo.loanType === 4 ? $t('table.applyInfo.financialLabel.flow') + $t('page.optional') : $t('table.applyInfo.financialLabel.flow')
				"
				size="default"
			>
				<a-descriptions-item :span="3">
					<a-table
						style="font-size: 12px"
						:columns="columns"
						size="default"
						:rowKey="(record, index) => index"
						:dataSource="financeInfo.transcations"
						:pagination="false"
						bordered
					>
						<a slot="file" slot-scope="text, obj" @click="openUrl(obj)">{{ obj.file }}</a>
					</a-table>
				</a-descriptions-item>
				<a-descriptions-item>
					<!-- <a-button type="primary" @click="SendLoanFileCredit" style="margin-left: 20px" :disabled="financeInfo.transcations.length == 0">
						{{ $t('page.reviewFile') }}
					</a-button> -->
					<a-button type="primary" @click="CheckLoanDetail" style="margin-left: 20px" :disabled="financeInfo.transcations.length == 0">
						{{ $t('page.checkReview') }}
					</a-button>
				</a-descriptions-item>
			</a-descriptions>
			<a-descriptions class="line-top-descriptions" bordered :title="$t('table.applyInfo.financialLabel.loanType')" size="default">
				<a-descriptions-item :label="$t('table.applyInfo.financialLabel.loanType')">
					{{ $t(`table.applyInfo.financialLabel.loanMap.${financeInfo.loanType}`) }}
				</a-descriptions-item>
			</a-descriptions>
			<a-descriptions class="line-bottom-descriptions" v-if="financeInfo.loanType == 1" bordered size="default">
				<a-descriptions-item :label="$t('table.applyInfo.loanApplyLabel.loanFees')" :span="3">
					<a-statistic :precision="2" :value="financeInfo.carAmount == null ? ' ' : financeInfo.carAmount" />
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.financialLabel.invoice') + $t('page.optional')" :span="3">
					<UploadPreviewFile :uploadFilePath="financeInfo.invoiceFile" :isUploadFileBtn="false"> </UploadPreviewFile>
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.financialLabel.contract') + $t('page.optional')" :span="3">
					<UploadPreviewFile :uploadFilePath="financeInfo.contractFile" :isUploadFileBtn="false"> </UploadPreviewFile>
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.financialLabel.proofofPayment') + $t('page.optional')" :span="3">
					<UploadPreviewFile :uploadFilePath="financeInfo.proofOfPayment" :isUploadFileBtn="false"> </UploadPreviewFile>
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.financialLabel.sellerAgency')">
					{{ financeInfo.sellerInstitution }}
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.financialLabel.contact') + $t('page.optional')">
					{{ financeInfo.sellerName }}
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.financialLabel.mobile') + $t('page.optional')">
					{{ financeInfo.sellerMobile }}
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.financialLabel.account')">
					{{ financeInfo.sellerAccount }}
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.financialLabel.purchaseTime') + $t('page.optional')">
					{{ financeInfo.purchaseTime }}
				</a-descriptions-item>
			</a-descriptions>
			<a-descriptions class="table-descriptions" :title="$t('table.applyInfo.applicationLabel.supDoc')" size="default">
				<a-descriptions-item>
					<a-table
						style="font-size: 12px"
						:columns="supCloums"
						size="default"
						:rowKey="(record, index) => index"
						:dataSource="financeInfo.supplementFiles"
						:pagination="false"
						bordered
					>
						<span slot="file" slot-scope="obj">
							<a v-bind:href="$Util.getObsImageUrl($store.state.pageState.authObj, obj.file)" target="_blank">{{
								!$Util.isEmpty(obj.file) ? obj.file.split('/').at(-1) : ''
							}}</a>
						</span>
					</a-table>
				</a-descriptions-item>
			</a-descriptions>
		</viewer>
		<!-- 流水弹窗 -->
		<a-modal v-drag-modal v-model="fullModal.show" title="Serial documents" :footer="false" width="100%" wrapClassName="full-modal">
			<a-tabs default-active-key="1">
				<a-tab-pane key="1" :tab="$t('table.useraccount.result.tab1')">
					<information :details.sync="details" v-if="fullModal.show"></information>
				</a-tab-pane>
				<a-tab-pane key="2" :tab="$t('table.useraccount.result.tab3')">
					<flowDetails :reqParam="fileNameList" :isFileResult="true" v-if="fullModal.show"></flowDetails>
				</a-tab-pane>
				<a-tab-pane key="3" :tab="$t('table.useraccount.result.tab2')" force-render>
					<transactions :id="financeInfo.transcations" :isFileResult="true" v-if="fullModal.show"></transactions>
				</a-tab-pane>
			</a-tabs>
		</a-modal>
	</div>
</template>
<script>
import { _axios } from '@/plugins/axios'
import { apiSendLoanFileCredit, apiLoanDetail, apiGetFileStatus } from '@/api/loan'
import moment from 'moment'
import information from '@/views/review/components/information'
import flowDetails from '@/views/review/components/flowDetails'
import transactions from '@/views/review/components/transactions'
export default {
	props: ['financeInfo'],
	data() {
		return {
			columns: [
				{
					// 流水机构
					title: this.$t('table.applyInfo.financialLabel.bankType'),
					dataIndex: 'institutionName',
					align: 'center'
				},
				{
					// 文件名称
					title: this.$t('table.applyInfo.financialLabel.fileName'),
					scopedSlots: { customRender: 'file' },
					align: 'center'
				},
				{
					// 文件密码
					title: this.$t('table.applyInfo.financialLabel.filePassword'),
					dataIndex: 'filePassword',
					align: 'center'
				},
				{
					// 状态
					title: this.$t('table.loanInfo.caseInfo.status'),
					dataIndex: 'status',
					align: 'center',
					customRender: (v, o) => (v ? this.$t(`table.useraccount.file_status_map.${v}`) : '-')
				}
			],
			fullModal: {
				show: false
			},
			details: null
		}
	},
	computed: {
		supCloums() {
			return [
				{
					// 文件名称
					title: this.$t('table.applyInfo.applicationLabel.supCloums.fileName'),
					dataIndex: 'filename',
					align: 'center',
					width: 300
				},
				{
					// 文件
					title: this.$t('table.applyInfo.applicationLabel.supCloums.file'),
					scopedSlots: { customRender: 'file' },
					align: 'center'
				},
				{
					// 文件备注
					title: this.$t('table.applyInfo.applicationLabel.supCloums.fileRemark'),
					dataIndex: 'note',
					align: 'center'
				}
			]
		},
		fileNameList() {
			let fileNameList = []
			this.financeInfo.transcations.map((item) => {
				fileNameList.push(item.file)
			})
			return fileNameList
		}
	},
	methods: {
		openUrl(val) {
			_axios
				.get(`/car/file/download/${val.file}`, { responseType: 'arraybuffer' })
				.then((res) => {
					window.open((window.URL || window.webkitURL).createObjectURL(new Blob([res], { type: 'application/pdf' })), '_blank')
				})
				.catch((err) => {
					console.log(err)
				})
		},
		moment: moment,
		// 发起流水审核
		SendLoanFileCredit() {
			let fileNames = []
			this.financeInfo.transcations.map((item) => {
				fileNames.push(item.file)
			})
			apiSendLoanFileCredit(fileNames)
				.then(() => {
					let fileNamesStr = ''
					fileNames.forEach((element) => {
						fileNamesStr += 'fileNames=' + element + '&'
					})
					apiGetFileStatus(fileNamesStr).then((res) => {
						if (res) {
							this.financeInfo.transcations.forEach((element) => {
								element.status = res[element.file]
							})
							this.$message.success('success')
						}
					})
				})
				.catch((err) => {
					console.log(err)
				})
		},
		// 查看流水解析结果
		CheckLoanDetail() {
			let fileNames = []
			this.financeInfo.transcations.map((item) => {
				fileNames.push(item.file)
			})
			let fileNamesStr = ''
			fileNames.forEach((element) => {
				fileNamesStr += 'fileNames=' + element + '&'
			})
			apiGetFileStatus(fileNamesStr).then((res) => {
				if (res) {
					this.financeInfo.transcations.forEach((element) => {
						element.status = res[element.file]
					})
				}
			})
			let params = {
				fileNames: '',
				date: this.moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
			}
			params.fileNames = fileNames.join(',')
			apiLoanDetail(params).then((res) => {
				this.details = res
				if (this.details) {
					this.fullModal.show = true
				}
			})
		}
	},
	components: {
		information,
		transactions,
		flowDetails
	}
}
</script>
<style scoped lang="less">
.ant-form-item {
	margin-bottom: 0;
}
img {
	display: inline-block;
	width: 100px;
	height: 100px;
	margin-right: 20px;
	cursor: pointer;
}
.full-modal {
	.ant-modal {
		max-width: 100%;
		top: 0;
		padding-bottom: 0;
		margin: 0;
	}
	.ant-modal-content {
		display: flex;
		flex-direction: column;
		height: calc(100vh);
	}
	.ant-modal-body {
		flex: 1;
	}
}
</style>
