<template>
	<!-- 申请资料 -->
	<div>
		<viewer>
			<a-descriptions bordered size="default">
				<a-descriptions-item :label="$t('table.applyInfo.applicationLabel.loanExcel')" :span="3">
					<UploadPreviewFile :uploadFilePath="loanApplyInfo.applyFile" :isUploadFileBtn="false"> </UploadPreviewFile>
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.applicationLabel.loanMoney')">
					<a-statistic :precision="2" :value="loanApplyInfo.applyAmount == null ? ' ' : loanApplyInfo.applyAmount" />
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.applicationLabel.loanTerm')">
					{{ loanApplyInfo.installmentsNumbers }}
				</a-descriptions-item>
			</a-descriptions>
			<a-descriptions class="table-descriptions" :title="$t('table.applyInfo.applicationLabel.relatives')" size="default">
				<a-descriptions-item>
					<a-table
						style="font-size: 12px"
						:columns="columns"
						size="default"
						:rowKey="(record, index) => index"
						:dataSource="loanApplyInfo.relatives"
						:pagination="false"
						bordered
					>
					</a-table>
				</a-descriptions-item>
			</a-descriptions>
			<a-descriptions bordered :title="$t('table.applyInfo.applicationLabel.social')" size="default">
				<a-descriptions-item :label="$t('table.applyInfo.applicationLabel.facebookAccount')" :span="3">
					{{ loanApplyInfo.socialAccounts[0] ? loanApplyInfo.socialAccounts[0].accountId : '' }}
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.applicationLabel.facebookPic')">
					<UploadPreviewFile :uploadFilePath="loanApplyInfo.socialAccounts[0].accountScreenshot" :isUploadFileBtn="false"> </UploadPreviewFile>
				</a-descriptions-item>
			</a-descriptions>
			<a-descriptions class="line-top-descriptions" bordered :title="$t('table.applyInfo.applicationLabel.isGuarantee')" size="default">
				<a-descriptions-item :label="$t('table.applyInfo.applicationLabel.isGuarantee')">
					{{ loanApplyInfo.hasGuarantor == 0 ? $t('page.no') : $t('page.yes') }}
				</a-descriptions-item>
			</a-descriptions>
			<a-descriptions class="line-middle-descriptions" v-if="loanApplyInfo.hasGuarantor == 1" bordered size="default">
				<a-descriptions-item :label="$t('table.applyInfo.personalLabel.gurForm')" :span="3">
					<UploadPreviewFile :uploadFilePath="loanApplyInfo.guarantor.guarantorForm" :isUploadFileBtn="false"> </UploadPreviewFile>
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.personalLabel.firstName')">
					{{ loanApplyInfo.guarantor.firstName }}
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.personalLabel.middleName')">
					{{ loanApplyInfo.guarantor.middleName }}
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.personalLabel.lastName')">
					{{ loanApplyInfo.guarantor.lastName }}
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.personalLabel.sex')">
					{{ $t(`table.applyInfo.personalLabel.sexMap.${loanApplyInfo.guarantor.sex}`) }}
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.personalLabel.mobile')">
					{{ loanApplyInfo.guarantor.mobile }}
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.personalLabel.idNumber')">
					{{ loanApplyInfo.guarantor.identityCard }}
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.personalLabel.identityPic')" :span="3">
					<UploadPreviewFile
						:uploadFilePath="[loanApplyInfo.guarantor.identityCardFrontPic, loanApplyInfo.guarantor.identityCardBackPic]"
						:isUploadFileBtn="false"
					>
					</UploadPreviewFile>
				</a-descriptions-item>
				<a-descriptions-item label="Photo" :span="3">
					<UploadPreviewFile :uploadFilePath="loanApplyInfo.guarantor.photo" :isUploadFileBtn="false"> </UploadPreviewFile>
				</a-descriptions-item>
			</a-descriptions>
			<a-descriptions
				class="line-bottom-descriptions"
				bordered
				v-if="loanApplyInfo.hasGuarantor == 1"
				:title="$t('table.applyInfo.personalLabel.pinfile')"
				size="default"
			>
				<a-descriptions-item label="PIN">
					{{ loanApplyInfo.guarantor.pin.pinNum }}
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.personalLabel.taxpayer')">
					{{ loanApplyInfo.guarantor.pin.taxpayerName }}
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.personalLabel.pinStatus')">
					{{ loanApplyInfo.guarantor.pin.status }}
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.personalLabel.taxStatus')">
					{{ loanApplyInfo.guarantor.pin.itaxStatus }}
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.personalLabel.taxInstitution')">
					{{ loanApplyInfo.guarantor.pin.itaxObligation }}
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.personalLabel.effectiveTime')">
					{{ loanApplyInfo.guarantor.pin.effectiveTime }}
				</a-descriptions-item>
			</a-descriptions>
			<a-descriptions class="table-descriptions" :title="$t('table.applyInfo.applicationLabel.supDoc')" size="default">
				<a-descriptions-item>
					<a-table
						style="font-size: 12px"
						:columns="supCloums"
						size="default"
						:rowKey="(record, index) => index"
						:dataSource="loanApplyInfo.supplementFiles"
						:pagination="false"
						bordered
					>
						<span slot="file" slot-scope="obj">
							<a v-bind:href="$Util.getObsImageUrl($store.state.pageState.authObj, obj.file)" target="_blank">{{
								!$Util.isEmpty(obj.file) ? obj.file.split('/').at(-1) : ''
							}}</a>
						</span>
					</a-table>
				</a-descriptions-item>
			</a-descriptions>
		</viewer>
	</div>
</template>
<script>
export default {
	props: ['loanApplyInfo'],
	data() {
		return {
			columns: [
				{
					// 姓名
					title: this.$t('table.applyInfo.applicationLabel.cloums.name'),
					dataIndex: 'name',
					align: 'center'
				},
				{
					// 电话
					title: this.$t('table.applyInfo.applicationLabel.cloums.mobile'),
					dataIndex: 'mobile',
					align: 'center'
				},
				{
					// 地址
					title: this.$t('table.applyInfo.applicationLabel.cloums.address'),
					dataIndex: 'address',
					align: 'center'
				}
			]
		}
	},
	computed: {
		supCloums() {
			return [
				{
					// 文件名称
					title: this.$t('table.applyInfo.applicationLabel.supCloums.fileName'),
					dataIndex: 'filename',
					align: 'center',
					width: 300
				},
				{
					// 文件
					title: this.$t('table.applyInfo.applicationLabel.supCloums.file'),
					scopedSlots: { customRender: 'file' },
					align: 'center'
				},
				{
					// 文件备注
					title: this.$t('table.applyInfo.applicationLabel.supCloums.fileRemark'),
					dataIndex: 'note',
					align: 'center'
				}
			]
		}
	}
}
</script>
<style scoped lang="less">
.ant-form-item {
	margin-bottom: 0;
}
img {
	display: inline-block;
	width: 100px;
	height: 100px;
	margin-right: 20px;
	cursor: pointer;
}
.input_box {
	display: inline-block;
	vertical-align: top;
	margin-bottom: 10px;
}
.line_span {
	margin-right: 10px;
}
.report {
	width: 100%;
	p {
		margin: 0 0 10px;
	}
}
</style>
