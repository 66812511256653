<template>
	<div>
		<a-card :title="$t('table.applyInfo.amountCloums.title')" size="default">
			<p>
				<span class="list_label">{{ $t('table.applyInfo.amountCloums.payName') }}</span>
				<span class="list_content">{{ disburseInfo.disburseName }}</span>
			</p>
			<p>
				<span class="list_label">{{ $t('table.applyInfo.amountCloums.payAccount') }}</span>
				<span class="list_content">{{ disburseInfo.disburseAccount }}</span>
			</p>
			<p>
				<span class="list_label">{{ $t('table.applyInfo.amountCloums.payType') }}</span>
				<span class="list_content">{{ disburseInfo.disburseType }}</span>
			</p>
			<p>
				<span class="list_label">{{ $t('table.applyInfo.amountCloums.payAmount') }}</span>
				<span class="list_content">{{ disburseInfo.disburseAmount }}</span>
			</p>
			<p>
				<span class="list_label">{{ $t('table.applyInfo.amountCloums.payTime') }}</span>
				<span class="list_content">{{ disburseInfo.paidTime }}</span>
			</p>
			<p>
				<span class="list_label">{{ $t('table.applyInfo.amountCloums.actionTime') }}</span>
				<span class="list_content">{{ disburseInfo.operationTime }}</span>
			</p>
			<p>
				<span class="list_label">{{ $t('table.Disbursement-Receipt') }}</span>
				<span class="list_content">
					<a :href="url">{{ name }}</a>
				</span>
			</p>
		</a-card>
	</div>
</template>
<script>
export default {
	props: ['disburseInfo'],
	data() {
		return {
			url: '',
			name: ''
		}
	},
	created() {
		this.$nextTick(() => {
			this.name = this.disburseInfo.disburseCertificateFile.split('/').at(-1)
			this.url = this.$Util.getObsImageUrl(this.$store.state.pageState.authObj, this.disburseInfo.disburseCertificateFile)
		})
	}
}
</script>
<style scoped lang="less">
p {
	padding: 10px 0;
	.list_label {
		display: inline-block;
		vertical-align: top;
		width: 120px;
	}
	.list_content {
		display: inline-block;
		vertical-align: top;
	}
}
</style>
