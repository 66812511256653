<template>
	<!-- 创建信息 start -->
	<a-descriptions bordered :title="$t('table.applyInfo.loanApplyLabel.creatingMes')" size="default">
		<a-descriptions-item :label="$t('table.applyInfo.loanApplyLabel.city')">
			<a-select v-if="editCreateInfo" v-model="createInfo.cityCode" @change="handleChangeCity">
				<a-select-option :value="item.id" v-for="item in cityList" :key="item.id">{{ isLang ? item.id : item.name }}</a-select-option>
			</a-select>
			<span v-else>{{ isLang ? createInfo.cityCode : createInfo.cityName }}</span>
		</a-descriptions-item>
		<a-descriptions-item :label="$t('table.applyInfo.loanApplyLabel.branchOffice')">
			<a-select v-if="editCreateInfo" v-model="createInfo.storeId" @change="handleChangeStore">
				<a-select-option :value="item.id" v-for="item in branchList" :key="item.id">{{ item.storeName }}</a-select-option>
			</a-select>
			<span v-else>{{ createInfo.storeName }}</span>
		</a-descriptions-item>
		<a-descriptions-item :label="$t('table.applyInfo.loanApplyLabel.salesAgent')">
			<a-select v-if="editCreateInfo" v-model="createInfo.salesmanId" @change="handleChangeSalesman">
				<a-select-option :value="item.salesmanId" v-for="item in salesmanList" :key="item.salesmanId">{{ item.salesmanName }}</a-select-option>
			</a-select>
			<span v-else>{{ createInfo.salesmanName }}</span>
		</a-descriptions-item>
	</a-descriptions>
	<!-- 创建信息 end -->
</template>
<script>
import { apiCityList, apiBranchList } from '@/api/branch'
import { mapGetters } from 'vuex'
export default {
	props: ['createInfo'],
	data() {
		return {
			editCreateInfo: false,
			cityList: [],
			branchList: [],
			branchAllList: [],
			salesmanList: []
		}
	},
	computed: {
		...mapGetters(['setting']),
		isLang() {
			return this.setting.curLanguage.locale === 'en'
		}
	},
	methods: {
		init() {
			Promise.all([apiCityList(), apiBranchList()]).then((res) => {
				this.cityList = res[0]
				this.branchAllList = res[1]
				if (this.createInfo.cityCode) {
					this.branchAllList.map((branchItem) => {
						if (branchItem.cityCode === this.createInfo.cityCode) {
							this.branchList.push(branchItem)
						}
					})
				}
				if (this.createInfo.storeId) {
					this.branchList.map((item) => {
						if (this.createInfo.storeId === item.id) {
							this.salesmanList = item.salesmen
						}
					})
				}
			})
		},
		handleChangeCity(val) {
			this.branchList = []
			this.createInfo.storeName = ''
			this.createInfo.storeId = ''
			this.createInfo.salesmanName = ''
			this.createInfo.salesmanId = ''
			this.cityList.map((item) => {
				if (val === item.id) {
					this.createInfo.cityName = item.name
				}
			})
			this.branchAllList.map((branchItem) => {
				if (branchItem.cityCode === val) {
					this.branchList.push(branchItem)
				}
			})
		},
		handleChangeSalesman(val) {
			this.salesmanList.map((item) => {
				if (val === item.salesmanId) {
					this.createInfo.salesmanName = item.salesmanName
				}
			})
		},
		handleChangeStore(val) {
			this.createInfo.salesmanName = ''
			this.createInfo.salesmanId = ''
			this.branchList.map((item) => {
				if (val === item.id) {
					this.createInfo.storeName = item.storeName
					this.salesmanList = [...item.salesmen]
				}
			})
		}
	},
	created() {
		this.$nextTick(() => {
			this.editCreateInfo ? this.init() : ''
		})
	}
}
</script>
