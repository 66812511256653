<template>
	<a-descriptions v-if="userInfo" bordered size="default">
		<template slot="title">
			<span class="row-start-center">
				<span style="margin-right: 100px">{{ $t('page.userInfo') }}</span>
				<slot name="titleBtn"></slot>
			</span>
		</template>
		<a-descriptions-item :label="$t('table.loanInfo.caseInfo.userId')">
			{{ userInfo.casePrefix + '-' + userInfo.caseId }}
		</a-descriptions-item>
		<a-descriptions-item :label="$t('table.loanInfo.caseInfo.createTime')">
			{{ userInfo.createTime }}
		</a-descriptions-item>
		<a-descriptions-item :label="$t('table.loanInfo.caseInfo.createType')">
			{{ $t(`table.applyInfo.createStatus.${userInfo.createSource}`) }}
		</a-descriptions-item>
		<a-descriptions-item :label="$t('table.loanInfo.caseInfo.creater')">
			{{ userInfo.creatorName }}
		</a-descriptions-item>
		<a-descriptions-item :label="$t('table.loanInfo.caseInfo.status')">
			{{ $t(`table.applyInfo.recordCloums.nodeStatus.${userInfo.statusCode}`) }}
		</a-descriptions-item>
		<a-descriptions-item label="Disbursement Method">
			{{ loanInfo?.disburseType }}
		</a-descriptions-item>
	</a-descriptions>
</template>
<script>
export default {
	props: ['userInfo', 'loanInfo'],
	data() {
		return {
			isShowDisbursementMethod:
				['menus.loanDataMenu.pendingDetail', 'menus.insuranceRenewalMenu.renewalDetail', 'menus.emergencyLoanMenu.detail'].includes(
					this.$route.name
				) &&
				['/home/loandata/pendinglist', '/home/insuranceRenewal/renewalPending', '/home/emergencyLoan/pendingList'].includes(this.$route.query.from)
		}
	}
}
</script>
