<template>
	<!-- 车辆资料 -->
	<div>
		<a-tabs :defaultActiveKey="1" type="card" :animated="true" style="margin-top: 20px">
			<a-tab-pane v-for="(carInfo, index) in loanCarInfos" :key="index + 1" :tab="$t('table.applyInfo.tabsMenu.vehicle') + ' ' + (index + 1)">
				<viewer>
					<a-tabs default-active-key="1">
						<a-tab-pane key="1">
							<template slot="tab">
								{{ `${$t('table.applyInfo.vehicleLabel.logFileInfo')} 1` }}
								<a-button
									type="primary"
									v-if="!carInfo.disbursedLogbook && pageState.fromPath.indexOf('finance') != -1 && parentNode === 'lendingdetail'"
									@click="addLogbook(index)"
								>
									Add logbook
								</a-button>
							</template>
							<a-descriptions bordered size="default">
								<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.logFile')" :span="3">
									<UploadPreviewFile :uploadFilePath="carInfo.logbook.logbookFile" :isUploadFileBtn="false"> </UploadPreviewFile>
								</a-descriptions-item>
								<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.vehicle')">
									{{ carInfo.logbook.make }}
								</a-descriptions-item>
								<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.model')">
									{{ carInfo.logbook.model }}
								</a-descriptions-item>
								<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.year')">
									{{ carInfo.logbook.year }}
								</a-descriptions-item>
								<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.license')">
									{{ carInfo.logbook.registrationNumber }}
								</a-descriptions-item>
								<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.frame')">
									{{ carInfo.logbook.frameNumber }}
								</a-descriptions-item>
								<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.engineNo')">
									{{ carInfo.logbook.engineNo }}
								</a-descriptions-item>
								<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.attribution')">
									<div class="box">
										<div class="list" v-for="(item, idx) in carInfo.logbook.belongs" :key="idx">
											<div class="span_labe"> PIN:{{ carInfo.logbook.belongs[idx].pin }} </div>
											<div class="span_labe"> Name:{{ carInfo.logbook.belongs[idx].name }} </div>
										</div>
									</div>
								</a-descriptions-item>
								<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.vehicleUse')">
									{{ carInfo.logbook.vehicleUse }}
								</a-descriptions-item>
							</a-descriptions>
						</a-tab-pane>
						<a-tab-pane key="2" :tab="`${$t('table.applyInfo.vehicleLabel.logFileInfo')} 2`" force-render v-if="carInfo.disbursedLogbook">
							<a-descriptions bordered size="default">
								<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.logFile')" :span="3">
									<UploadPreviewFile :uploadFilePath="carInfo.disbursedLogbook.logbookFile" :isUploadFileBtn="false"> </UploadPreviewFile>
								</a-descriptions-item>
								<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.vehicle')">
									{{ carInfo.disbursedLogbook.make }}
								</a-descriptions-item>
								<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.model')">
									{{ carInfo.disbursedLogbook.model }}
								</a-descriptions-item>
								<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.year')">
									{{ carInfo.disbursedLogbook.year }}
								</a-descriptions-item>
								<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.license')">
									{{ carInfo.disbursedLogbook.registrationNumber }}
								</a-descriptions-item>
								<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.frame')">
									{{ carInfo.disbursedLogbook.frameNumber }}
								</a-descriptions-item>
								<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.engineNo')">
									{{ carInfo.disbursedLogbook.engineNo }}
								</a-descriptions-item>
								<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.attribution')">
									<div class="box">
										<div class="list" v-for="(item, idx) in carInfo.disbursedLogbook.belongs" :key="idx">
											<div class="span_labe"> PIN:{{ carInfo.disbursedLogbook.belongs[idx].pin }} </div>
											<div class="span_labe"> Name:{{ carInfo.disbursedLogbook.belongs[idx].name }} </div>
										</div>
									</div>
								</a-descriptions-item>
								<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.vehicleUse')">
									{{ carInfo.disbursedLogbook.vehicleUse }}
								</a-descriptions-item>
							</a-descriptions>
						</a-tab-pane>
					</a-tabs>
					<a-descriptions bordered size="default">
						<template slot="title">
							{{ $t('table.applyInfo.vehicleLabel.carPic') }}
							<a-button v-if="parentNode === 'lendingdetail' && loanType === 7" type="primary" @click="updateInfo(carInfo)"> Upload </a-button>
						</template>
						<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.carFace')" :span="2">
							<UploadPreviewFile :uploadFilePath="carInfo.pic.appearancePics" :isUploadFileBtn="false"> </UploadPreviewFile>
						</a-descriptions-item>
						<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.carInside')" :span="2">
							<UploadPreviewFile :uploadFilePath="carInfo.pic.interiorPics" :isUploadFileBtn="false"> </UploadPreviewFile>
						</a-descriptions-item>
						<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.down')" :span="2">
							<UploadPreviewFile :uploadFilePath="carInfo.pic.chassisPics" :isUploadFileBtn="false"> </UploadPreviewFile>
						</a-descriptions-item>
						<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.engine')" :span="2">
							<UploadPreviewFile :uploadFilePath="carInfo.pic.enginePics" :isUploadFileBtn="false"> </UploadPreviewFile>
						</a-descriptions-item>
						<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.detail')" :span="2">
							<UploadPreviewFile :uploadFilePath="carInfo.pic.detailPics" :isUploadFileBtn="false"> </UploadPreviewFile>
						</a-descriptions-item>
					</a-descriptions>
					<a-descriptions bordered size="default">
						<template slot="title">
							{{ $t('table.applyInfo.vehicleLabel.estimateBookFileTxt') }}
							<a-button v-if="parentNode === 'lendingdetail' && loanType === 7" type="primary" @click="updateInfo(carInfo)"> Upload</a-button>
						</template>
						<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.estimateBookFile')" :span="3">
							<UploadPreviewFile :uploadFilePath="carInfo.valuation.file" :isUploadFileBtn="false"> </UploadPreviewFile>
						</a-descriptions-item>
						<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.vehicle')">
							{{ carInfo.valuation.make }}
						</a-descriptions-item>
						<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.model')">
							{{ carInfo.valuation.model }}
						</a-descriptions-item>
						<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.year')">
							{{ carInfo.valuation.year }}
						</a-descriptions-item>
						<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.license')">
							{{ carInfo.valuation.registrationNumber }}
						</a-descriptions-item>
						<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.frame')">
							{{ carInfo.valuation.frameNumber }}
						</a-descriptions-item>
						<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.engineNo')">
							{{ carInfo.valuation.engineNo }}
						</a-descriptions-item>
						<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.estimate')">
							{{ carInfo.valuation.valuation }}
						</a-descriptions-item>
						<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.endValue')">
							{{ carInfo.valuation.saleValuation }}
						</a-descriptions-item>
						<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.createTime')">
							{{ carInfo.valuation.reportTime }}
						</a-descriptions-item>
					</a-descriptions>
					<a-descriptions :title="$t('table.applyInfo.vehicleLabel.insurance')" bordered size="default">
						<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.allInsurance')">
							{{ carInfo.insurance.isAllRisks == 1 ? $t('page.yes') : $t('page.no') }}
						</a-descriptions-item>
						<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.expTime')" :span="2">
							{{ carInfo.insurance.expireTime }}
						</a-descriptions-item>
						<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.stickersPic')" :span="3">
							<UploadPreviewFile :uploadFilePath="carInfo.insurance.insurancePic" :isUploadFileBtn="false"> </UploadPreviewFile>
						</a-descriptions-item>
					</a-descriptions>
					<a-descriptions class="table-descriptions" :title="$t('table.applyInfo.applicationLabel.supDoc')" size="default">
						<a-descriptions-item>
							<a-table
								style="font-size: 12px"
								:columns="supCloums"
								size="default"
								:rowKey="(record, index) => index"
								:dataSource="carInfo.supplementFiles"
								:pagination="false"
								bordered
							>
								<span slot="file" slot-scope="obj">
									<a v-bind:href="$Util.getObsImageUrl($store.state.pageState.authObj, obj.file)" target="_blank">{{
										!$Util.isEmpty(obj.file) ? obj.file.split('/').at(-1) : ''
									}}</a>
								</span>
							</a-table>
						</a-descriptions-item>
					</a-descriptions>
				</viewer>
			</a-tab-pane>
		</a-tabs>
		<!-- 增加logbook -->
		<a-modal
			v-drag-modal
			v-model="logbookShow"
			width="1100px"
			:title="$t('table.applyInfo.vehicleLabel.logFileInfo')"
			okText="Confirm"
			@ok="logbookOk"
		>
			<a-descriptions bordered size="small">
				<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.logFile')" :span="3">
					<UploadPreviewFile :uploadFilePath.sync="disbursedLogbook.logbookFile" :folderPath="$route.query.id"></UploadPreviewFile>
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.vehicle')">
					<a-auto-complete v-model="disbursedLogbook.make" :data-source="vehicleMakeData" placeholder="Vehicle Make" />
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.model')">
					<a-auto-complete v-model="disbursedLogbook.model" :data-source="vehicleModelData" placeholder="Vehicle Model" />
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.year')">
					<a-input v-model="disbursedLogbook.year" placeholder="Vehicle Years" />
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.license')">
					<a-input v-model="disbursedLogbook.registrationNumber" />
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.frame')">
					<a-input v-model="disbursedLogbook.frameNumber" />
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.engineNo')">
					<a-input v-model="disbursedLogbook.engineNo" />
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.attribution')" :span="3">
					<div class="box">
						<div class="list" v-for="(item, idx) in disbursedLogbook.belongs" :key="idx">
							<div class="span_labe">
								PIN:
								<a-input v-model="disbursedLogbook.belongs[idx].pin" />
							</div>
							<div class="span_labe">
								Name:
								<a-input v-model="disbursedLogbook.belongs[idx].name" />
							</div>
							<a-button type="primary" @click="DelBelongs(idx)">{{ $t('page.delete') }}</a-button>
						</div>
						<div class="add-btn">
							<a-button type="primary" @click="AddBelongs" :disabled="disbursedLogbook.belongs.length >= 2">
								{{ $t('page.add') }}
							</a-button>
						</div>
					</div>
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.vehicleUse')">
					<a-auto-complete v-model="disbursedLogbook.vehicleUse" :data-source="$enumMap.vehicleUseEnum" placeholder="Vehicle Use" />
				</a-descriptions-item>
			</a-descriptions>
		</a-modal>
		<!-- 更新车照及估计报告 -->
		<a-modal
			v-drag-modal
			v-model="updateInfoShow"
			width="1300px"
			:title="$t('table.applyInfo.vehicleLabel.carPic') + ' / ' + $t('table.applyInfo.vehicleLabel.estimateBookFile')"
			okText="Confirm"
			@ok="updateInfoOk"
		>
			<a-descriptions bordered :title="$t('table.applyInfo.vehicleLabel.carPic')" size="small">
				<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.carFace')" :span="2">
					<UploadPreviewFile :uploadFilePath.sync="updateCarInfo.pic.appearancePics" :folderPath="updateCarInfo.loanId" :isImgFile="true">
						<template v-slot:btn>
							<span
								class="example-click"
								@click="
									exampleModal.show = true
									exampleModal.type = 1
								"
								>Example<a-icon type="exclamation-circle"
							/></span>
						</template>
					</UploadPreviewFile>
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.carInside')" :span="2">
					<UploadPreviewFile :uploadFilePath.sync="updateCarInfo.pic.interiorPics" :folderPath="updateCarInfo.loanId" :isImgFile="true">
						<template v-slot:btn>
							<span
								class="example-click"
								@click="
									exampleModal.show = true
									exampleModal.type = 2
								"
								>Example<a-icon type="exclamation-circle"
							/></span>
						</template>
					</UploadPreviewFile>
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.down')" :span="2">
					<UploadPreviewFile :uploadFilePath.sync="updateCarInfo.pic.chassisPics" :folderPath="updateCarInfo.loanId" :isImgFile="true">
						<template v-slot:btn>
							<span
								class="example-click"
								@click="
									exampleModal.show = true
									exampleModal.type = 3
								"
								>Example<a-icon type="exclamation-circle"
							/></span>
						</template>
					</UploadPreviewFile>
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.engine')" :span="2">
					<UploadPreviewFile :uploadFilePath.sync="updateCarInfo.pic.enginePics" :folderPath="updateCarInfo.loanId" :isImgFile="true">
						<template v-slot:btn>
							<span
								class="example-click"
								@click="
									exampleModal.show = true
									exampleModal.type = 4
								"
								>Example<a-icon type="exclamation-circle"
							/></span>
						</template>
					</UploadPreviewFile>
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.detail')" :span="2">
					<UploadPreviewFile :uploadFilePath.sync="updateCarInfo.pic.detailPics" :folderPath="updateCarInfo.loanId" :isImgFile="true">
						<template v-slot:btn>
							<span
								class="example-click"
								@click="
									exampleModal.show = true
									exampleModal.type = 5
								"
								>Example<a-icon type="exclamation-circle"
							/></span>
						</template>
					</UploadPreviewFile>
				</a-descriptions-item>
			</a-descriptions>
			<a-descriptions bordered :title="$t('table.applyInfo.vehicleLabel.estimateBookFile')" size="small">
				<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.estimateBookFile')">
					<UploadPreviewFile :uploadFilePath.sync="updateCarInfo.valuation.file" :folderPath="updateCarInfo.loanId" :callbackFun="getValuation">
						<template v-slot:btn>
							<a-button @click="estimateBookFileTxtBool = !estimateBookFileTxtBool" style="margin-left: 10px">
								{{ $t('table.applyInfo.personalLabel.fillManual') }}
							</a-button>
						</template>
					</UploadPreviewFile>
				</a-descriptions-item>
			</a-descriptions>
			<a-descriptions v-show="estimateBookFileTxtBool" bordered :title="$t('table.applyInfo.vehicleLabel.estimateBookFileTxt')" size="small">
				<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.vehicle')">
					<a-auto-complete v-model="updateCarInfo.valuation.make" :data-source="vehicleMakeData" placeholder="Vehicle Make" />
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.model')">
					<a-auto-complete v-model="updateCarInfo.valuation.model" :data-source="vehicleModelData" placeholder="Vehicle Model" />
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.year')">
					<a-input v-model="updateCarInfo.valuation.year" placeholder="Vehicle Years" />
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.license')">
					<a-input v-model="updateCarInfo.valuation.registrationNumber" />
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.frame')">
					<a-input v-model="updateCarInfo.valuation.frameNumber" />
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.engineNo')">
					<a-input v-model="updateCarInfo.valuation.engineNo" />
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.estimate')">
					<a-input-number v-model="updateCarInfo.valuation.valuation" :precision="0" />
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.endValue')">
					<a-input-number v-model="updateCarInfo.valuation.saleValuation" :precision="0" />
				</a-descriptions-item>
				<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.createTime')">
					<a-date-picker :valueFormat="dateFormat" showTime type="date" v-model="updateCarInfo.valuation.reportTime" />
				</a-descriptions-item>
			</a-descriptions>
		</a-modal>
		<!-- 车辆归属 -->
		<a-modal v-drag-modal v-model="belongsModel.show" :title="belongsModel.title" :destroyOnClose="true" width="800px" @ok="handModalOk">
			<a-form-model :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
				<a-form-model-item label="PIN">
					<a-input v-model="belongsModel.pin" />
				</a-form-model-item>
				<a-form-model-item label="Name">
					<a-input v-model="belongsModel.name" />
				</a-form-model-item>
			</a-form-model>
		</a-modal>
		<a-modal v-drag-modal v-model="exampleModal.show" title="Example" class="example">
			<template slot="footer">
				<span></span>
			</template>
			<div class="example-info" v-show="exampleModal.type === 1">
				<p>Vehicle exterior</p>
				<p>Take the photos as the following examples</p>
			</div>
			<div class="example-info" v-show="exampleModal.type === 2">
				<p>Vehicle Interior</p>
				<p>Take the photos as the following examples</p>
			</div>
			<div class="example-info" v-show="exampleModal.type === 3">
				<p>Vehicle chassis</p>
				<p>Take the photos as the following examples</p>
			</div>
			<div class="example-info" v-show="exampleModal.type === 4">
				<p>Vehicle engine</p>
				<p>Take the photos as the following examples</p>
			</div>
			<div class="example-info" v-show="exampleModal.type === 5">
				<p>Vehicle details</p>
				<p>Take the photos as the following examples</p>
			</div>
			<a-row :gutter="16" class="example-img">
				<viewer v-show="exampleModal.type === 1">
					<a-row>
						<a-col :span="12">
							<a-card title="Front of vehicle" :bordered="false">
								<img src="@/assets/exterior-1@2x.png" />
							</a-card>
						</a-col>
					</a-row>
					<a-row :gutter="16">
						<p class="ant-card-head-title" style="display: block; padding: 16px 8px">Side of vehicle</p>
						<a-col :span="12" style="margin-bottom: 16px">
							<a-card :bordered="false">
								<img src="@/assets/exterior-2@2x.png" />
							</a-card>
						</a-col>
						<a-col :span="12" style="margin-bottom: 16px">
							<a-card :bordered="false">
								<img src="@/assets/exterior-3@2x.png" />
							</a-card>
						</a-col>
						<a-col :span="12" style="margin-bottom: 16px">
							<a-card :bordered="false">
								<img src="@/assets/exterior-4@2x.png" />
							</a-card>
						</a-col>
					</a-row>
					<a-row>
						<a-col :span="12">
							<a-card title="Back of vehicle" :bordered="false">
								<img src="@/assets/exterior-5@2x.png" />
							</a-card>
						</a-col>
					</a-row>
				</viewer>
				<viewer v-show="exampleModal.type === 2">
					<a-col :span="12">
						<a-card title="Front Interior" :bordered="false">
							<img src="@/assets/Interior-1@2x.png" />
						</a-card>
					</a-col>
					<a-col :span="12">
						<a-card title="Dashboard" :bordered="false">
							<img src="@/assets/Interior-2@2x.png" />
						</a-card>
					</a-col>
					<a-col :span="12">
						<a-card title="Front Seat" :bordered="false">
							<img src="@/assets/Interior-3@2x.png" />
						</a-card>
					</a-col>
					<a-col :span="12">
						<a-card title="Back Seat" :bordered="false">
							<img src="@/assets/Interior-4@2x.png" />
						</a-card>
					</a-col>
					<a-col :span="12">
						<a-card title="Trunk" :bordered="false">
							<img src="@/assets/Interior-5@2x.png" />
						</a-card>
					</a-col>
				</viewer>
				<viewer v-show="exampleModal.type === 3">
					<img src="@/assets/details-2@2x.png" />
				</viewer>
				<viewer v-show="exampleModal.type === 4">
					<img src="@/assets/engine@2x.png" />
				</viewer>
				<viewer v-show="exampleModal.type === 5">
					<img src="@/assets/details-1@2x.png" />
				</viewer>
			</a-row>
		</a-modal>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import { apiDisbursedLogbook, apiGetValuation, apiPicValuation } from '@/api/loan'
export default {
	data() {
		return {
			dateFormat: 'YYYY-MM-DD HH:mm:ss',
			logbookShow: false,
			disbursedLogbook: {
				id: '',
				loanId: this.$route.query.id,
				applyCarId: '',
				logbookFile: '',
				make: '',
				model: '',
				year: '',
				registrationNumber: '',
				frameNumber: '',
				engineNo: '',
				belongs: [],
				vehicleUse: ''
			},
			addIndex: 0,
			belongsModel: {
				title: '',
				show: false,
				pin: '',
				name: ''
			},
			updateInfoShow: false,
			estimateBookFileTxtBool: false,
			updateCarInfo: {
				pic: {},
				valuation: {}
			},
			exampleModal: {
				show: false,
				type: 1
			}
		}
	},
	props: ['loanCarInfos', 'parentNode', 'loanType'],
	computed: {
		...mapGetters(['pageState']),
		supCloums() {
			return [
				{
					// 文件名称
					title: this.$t('table.applyInfo.applicationLabel.supCloums.fileName'),
					dataIndex: 'filename',
					align: 'center',
					width: 300
				},
				{
					// 文件
					title: this.$t('table.applyInfo.applicationLabel.supCloums.file'),
					scopedSlots: { customRender: 'file' },
					align: 'center'
				},
				{
					// 文件备注
					title: this.$t('table.applyInfo.applicationLabel.supCloums.fileRemark'),
					dataIndex: 'note',
					align: 'center'
				}
			]
		},
		vehicleData() {
			const json = require('@/utils/car.json')
			let newkey = Object.keys(json).sort()
			let vehicleData = {} //创建一个新的对象，用于存放排好序的键值对
			for (var i = 0; i < newkey.length; i++) {
				vehicleData[newkey[i]] = json[newkey[i]]
			}
			return vehicleData
		},
		vehicleMakeData() {
			return Object.keys(this.vehicleData)
		},
		vehicleModelData() {
			let vehicleModelData = []
			if (!this.$Util.isEmpty(this.disbursedLogbook.make) && this.vehicleData[this.disbursedLogbook.make]) {
				vehicleModelData = this.vehicleData[this.disbursedLogbook.make].map((item) => {
					return item.title
				})
				vehicleModelData.push('Other')
			}
			return vehicleModelData
		}
	},
	methods: {
		addLogbook(index) {
			this.disbursedLogbook = {
				id: '',
				loanId: this.$route.query.id,
				applyCarId: this.loanCarInfos[index].id,
				logbookFile: '',
				make: '',
				model: '',
				year: '',
				registrationNumber: '',
				frameNumber: '',
				engineNo: '',
				belongs: [],
				vehicleUse: ''
			}
			this.logbookShow = true
			this.addIndex = index
		},
		logbookOk() {
			apiDisbursedLogbook({ ...this.disbursedLogbook }).then(() => {
				this.$message.success('success')
				this.loanCarInfos[this.addIndex].disbursedLogbook = this.disbursedLogbook
				this.logbookShow = false
			})
		},
		AddBelongs() {
			this.belongsModel.title = this.$t('page.add')
			this.belongsModel.pin = ''
			this.belongsModel.name = ''
			this.belongsModel.show = true
		},
		DelBelongs(idx) {
			this.disbursedLogbook.belongs.splice(idx, 1)
		},
		handModalOk() {
			const pinRege = /^[A-Za-z0-9]{11}$/
			if (!pinRege.test(this.belongsModel.pin)) {
				this.$message.error('pin error')
				return false
			} else if (!this.belongsModel.name) {
				this.$message.error('name error')
				return false
			}
			this.disbursedLogbook.belongs.push({
				pin: this.belongsModel.pin,
				name: this.belongsModel.name
			})
			this.belongsModel.show = false
			this.belongsModel.pin = ''
			this.belongsModel.name = ''
		},
		getValuation(type) {
			apiGetValuation({ filePath: type })
				.then((res) => {
					this.updateCarInfo.valuation = Object.assign(this.updateCarInfo.valuation, res)
					this.estimateBookFileTxtBool = true
				})
				.catch(() => {
					this.estimateBookFileTxtBool = false
					this.updateCarInfo.valuation = Object.assign(this.updateCarInfo.valuation, {
						engineNo: '',
						frameNumber: '',
						valuation: '',
						year: '',
						registrationNumber: '',
						model: '',
						make: '',
						saleValuation: '',
						reportTime: ''
					})
				})
		},
		updateInfo(carInfo) {
			this.updateCarInfo = carInfo
			this.updateInfoShow = true
		},
		updateInfoOk() {
			apiPicValuation({
				applyCarId: this.updateCarInfo.id,
				loanId: this.updateCarInfo.loanId,
				pic: this.updateCarInfo.pic,
				valuation: this.updateCarInfo.valuation
			}).then(() => {
				this.$message.success('success')
				this.updateInfoShow = false
				this.updateCarInfo = {
					pic: {},
					valuation: {}
				}
			})
		}
	},
	created() {
		if (!this.loanCarInfos) {
			this.loanCarInfos = []
		}
	}
}
</script>
<style scoped lang="less">
.input_box {
	display: inline-block;
	vertical-align: top;
	margin-bottom: 10px;
}
.ant-input,
.ant-input-number,
.ant-select {
	width: 200px;
}
</style>
